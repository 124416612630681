import React from 'react';
import { render } from 'react-dom';
import './index.scss';
import { App } from 'components/App';
import reportWebVitals from './reportWebVitals';
import { compose } from 'utils/compose';
import { withStrictMode } from 'utils/StrictMode';
import { withWeb3Provider } from 'hooks/useWeb3';
import { withContractProvider } from 'hooks/useContract';
import { withRefetchProvider } from 'hooks/useRefetch';

const ComposedApp = compose(
  withStrictMode,
  withRefetchProvider,
  withWeb3Provider,
  withContractProvider,
  App
);

render(<ComposedApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
