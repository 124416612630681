const toDateTime = (secs) => {
    if(secs === 0)
        return '';
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    const date = t.toISOString().split('T')[0];
    const time = t.toTimeString().split(' ')[0];
    return `${date} ${time}`
  }

const parseDate = (stringDate) =>{
    if(!stringDate)
        return  new Date(1970, 0, 1);
    var arr = stringDate.split(/[- :]/);
    var date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    return date;
}

const formatSecsToDate = (secs) =>{
    if(secs === 0)
        return '';
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    const date = t.toISOString().split('T')[0];
    return `${date}`
}

const formatDuration = (secs) =>{
    if(secs === 0)
        return '0 Days';
    var days = Math.floor(secs /  (24 * 60 * 60));
    var hours = Math.floor((secs % (24 * 60 * 60)) / (60 * 60));
    var minutes = Math.floor((secs % (60 * 60)) / (60));
    var seconds = Math.floor(secs % 60);
    return `${days} Days ${hours}:${minutes}:${seconds}`; 
}

const formatDurationInDays = (secs) =>{
    if(secs === 0)
        return '0 Days';
    var days = Math.floor(secs /  (24 * 60 * 60));
    return `${days} Days`; 
}

const formatNumber = (number, minimumFractionDigits = 0, maximumFractionDigits = 3) =>{
    return Intl.NumberFormat(undefined,{minimumFractionDigits: minimumFractionDigits,maximumFractionDigits: maximumFractionDigits }).format(number);
}

const parseLocaleNumber = (stringNumber) => {
    var thousandSeparator = Intl.NumberFormat().format(11111).replace(/\p{Number}/gu, '');
    var decimalSeparator = Intl.NumberFormat().format(1.1).replace(/\p{Number}/gu, '');

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    );
}

const formatPercentage = (percentage) =>{
    var option = {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
     };
     if(isNaN(percentage))
     {
         return '0%';
     }
     var formatter = new Intl.NumberFormat("en-US",option);
     var percentageFormat = formatter.format(percentage);
     return percentageFormat;
}

  export { toDateTime, formatNumber, parseLocaleNumber, formatPercentage, formatSecsToDate, formatDuration, formatDurationInDays, parseDate };