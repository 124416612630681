const compose = (...composables) => {
  let Component = composables.pop();
  let composable;
  while ((composable = composables.pop())) {
    Component = composable(Component);
  }
  return Component;
};

const createComposable = Composable => Component => props =>
  (
    <Composable>
      <Component {...props} />
    </Composable>
  );

export { compose, createComposable };
