import React from "react";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {formatNumber, formatPercentage, formatDurationInDays, parseDate} from 'utils/Format';
import { useState } from 'react';

const cx = classNames.bind(styles);

const StakesTable = ({ stakingParameters, stake, account, onWithdraw }) => {
  
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [withdrawAmountReward, setWithdrawAmountReward] = useState('');
    //const [withdrawAmountPercentage, setWithdrawAmountPercentage] = useState('0');
    const onRewardDepositChanges = (eventSource) =>
    {
      const newValue = (parseFloat(eventSource.target.value));
      setWithdrawAmount(newValue);
      //setWithdrawAmountPercentage(newValue * 100 / stake?._amount);
      setWithdrawAmountReward(stake?._estimatedReward * newValue / stake?._amount);
    };

    const onMaxClicked = ()=>
    {
      setWithdrawAmount(stake?._amount);
      //setWithdrawAmountPercentage(100);
      setWithdrawAmountReward(stake?._estimatedReward);
    };

    // const onPercentageWithdrawAmountChange = (eventSource) =>{
    //   const newValue = (parseFloat(eventSource.target.value) * parseFloat(stake?._amount) / 100);
    //   setWithdrawAmount(newValue);
    //   //setWithdrawAmountPercentage(parseLocaleNumber(eventSource.target.value));
    //   setWithdrawAmountReward(stake?._estimatedReward * newValue / stake?._amount);
    // }

    const onWithdrawClick = () =>
    {
      onWithdraw(withdrawAmount);
    }
    const timeLeftForPenalty = Math.max(0, Number(stakingParameters?._cliffDuration) + Number(stakingParameters?._linearDuration) - ((new Date()).getTime()  - parseDate(stake?._rewardStartDate).getTime())/ 1000);

    return ( <div className={cx('container')}>
    <div className={cx('header')}>
        <h2>Your KATA Stake</h2>
      </div>
    {(stake._exists && 
      <div className={[cx('nestedContainer'), cx('stakeCard')].join(' ')}>
              <div className={[cx('parameterView')].join(' ')}>
                Stake in KATA:
                <h3>{formatNumber(stake?._amount)}</h3>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              STAKA Amount:
              <h3>{formatNumber(stake?._stakaAmount)}</h3>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              APY:
              <h3>{formatPercentage(stake?._apy)}</h3>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              Reward in KATA (Including any early unstaking penalty) *:
              <h3>{formatNumber(stake?._estimatedReward)}</h3>
              <p>* This reward is indicative if you were to withdraw right now. It is not guaranteed. Your rewards are only an estimate and could go up or down depending on your equitable share of the total staked KATA.</p>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              Created on (Date of the first stake):
              <h3>{stake?._since}</h3>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              Reward start date *:
              <h3>{stake?._rewardStartDate}</h3>
              <p>* Reward start date may differ from the first time you stake KATA, for example if you stake KATA coins at a given date and then after few days you stake more coins the rewards start date is updated proportionally, see FAQ for the more details.</p>
              </div>
              <div className={[cx('parameterView')].join(' ')}>
              Number of days left before early unstaking penalty is lifted:
              {(timeLeftForPenalty > 0 &&
              <h3>{formatDurationInDays(timeLeftForPenalty)}</h3>)}
              {(timeLeftForPenalty === 0 &&
              <h3>No early unstaking penalty is applicable.</h3>)}
              </div>
              {/* <div className={[cx('parameterView')].join(' ')}>
                <h3>Withdraw:</h3>
              </div> */}
              <div className={[cx('parameterView')].join(' ')}>
                <div className={[cx('numericStakeInput')].join(' ')}>
                    <label htmlFor="Amount_kata">
                      <input type="number" name="Amount (KATA)" id="Amount_kata"
                              step={100} value={(withdrawAmount)}
                              onChange={onRewardDepositChanges} min={0} max={stake?._amount} />
                      <span className={cx('placeholder')}>Amount (KATA)</span>
                      <span className={cx('placeholderBottom')}>(Reward: {formatNumber(withdrawAmountReward)})</span>
                      <button type="button" onClick={onMaxClicked}>MAX</button>
                    </label>
                  </div>
                  {/* <input className={cx('inputrange')} type='range' id="amountPercent" name="volume" value={withdrawAmountPercentage}
                          onChange={onPercentageWithdrawAmountChange} min="0" max="100"/>
                  <h5>{formatPercentage(withdrawAmountPercentage / 100)}</h5> */}
                  <div className={cx('withdrawButtonDiv')}>
                  <button className='btn rounded' onClick={onWithdrawClick}><span>Withdraw</span></button>
                  </div>
              </div>
              
              
          {/* <Gauge min={0} max={10000} value={2000} width={150} height={130} label="Accrued Reward" 
                backgroundColor="#20a121"
                color="#cf922c" /> */}
        </div>)}
  </div>
    );
};

export { StakesTable };
