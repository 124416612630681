import { useWeb3 } from 'hooks/useWeb3';
import { useEffect, useState } from 'react';
import { StakesTable } from 'components/StakesTable';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import LoaderSpinner from 'react-loader-spinner';
import { toDateTime, formatNumber, formatPercentage } from 'utils/Format';
import { useRefetch } from 'hooks/useRefetch';
import { Accordion } from 'react-bootstrap';
import { Equation, defaultErrorHandler } from "react-equation";

const cx = classNames.bind(styles);

const NormalizeStake = (web3, stake) => {
  const normalizedStake = {
    ...stake,
    _amount: web3.utils.fromWei(stake._amount, 'ether'),
    _stakaAmount: web3.utils.fromWei(stake._stakaAmount, 'ether'),
    _since: toDateTime(stake._since),
    _rewardStartDate: toDateTime(stake._rewardStartDate),
    _estimatedReward: web3.utils.fromWei(stake._estimatedReward, 'ether'),
    _apy:
      Number.parseInt(stake._estimatedAPY._apy) /
      Number.parseInt(stake._estimatedAPY._base),
  };
  return normalizedStake;
};

const StakingContractStakeholder = ({
  contract,
  tokenContract,
  stakingParameters,
  totalRewardsClaimed,
  currentApy,
  totalAmountStaked
}) => {
  //className={cx('container')}
  const [newStakeAmount, setNewStakeAmount] = useState(0);
  const [normalizedStake, setStake] = useState([]);
  const [accountBalance, setAccountBalance] = useState(0);
  const [accountAllowance, setAccountAllowance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validationError, setValiadationError] = useState('');
  const refetchController = useRefetch();

  const { web3, account } = useWeb3();

  const contractAddress = contract?._address;
  useEffect(() => {
    if(!account)
    {
      setStake([]);
      setAccountBalance(0);
      setAccountAllowance(0);
    }
    if (!account || !tokenContract) return;

    const handler = () => {
      tokenContract.methods.allowance(account, contractAddress).call(
        {
          from: account,
        },
        (err, result) => {
          if (err) {
            console.error(err);
          } else {
            setAccountAllowance(web3.utils.fromWei(result, 'ether'));
          }
        }
      );

      tokenContract.methods.balanceOf(account).call(
        {
          from: account,
        },
        (err, result) => {
          if (err) {
            console.error(err);
          } else {
            setAccountBalance(web3.utils.fromWei(result, 'ether'));
          }
        }
      );
      contract.methods.getStake().call({ from: account }, (err, result) => {
        if (err) {
          setStake([]);
        } else {
          const stake = NormalizeStake(web3, result);
          setStake(stake);
        }
      });
    };

    handler();
    refetchController.on('refetch', handler);
    return () => refetchController.removeListener('refetch', handler);
  }, [
    refetchController,
    account,
    contractAddress,
    tokenContract,
    web3,
    contract,
  ]);

  const onWithdraw = async amount => {
    try {
      setLoading(true);
      await contract.methods
        .withdrawStake(web3.utils.toWei(amount.toString(), 'ether'))
        .send({
          from: account,
        });
      refetchController.refetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onMaxClicked = () => {
    setNewStakeAmount(accountBalance);
  };

  const onStake = async eventSource => {
    try {
      setValiadationError('');
      setLoading(true);
      if (
        !newStakeAmount ||
        !accountAllowance ||
        Number(newStakeAmount) > Number(accountAllowance)
      ) {
        setValiadationError(
          `You need to increase the spending allowance. Click on the "authorize" button`
        );
        return;
      }
      if (
        !newStakeAmount ||
        !accountBalance ||
        Number(newStakeAmount) > Number(accountBalance)
      ) {
        setValiadationError(
          `The amount you are trying to stake is above your balance in $KATA: ${formatNumber(accountBalance)}.
          Make sure you are connected to the correct chain (Ethereum or BSC)`
        );
        return;
      }
      if (
        Number(newStakeAmount) <
        Number(web3.utils.fromWei(stakingParameters._minimumStake))
      ) {
        setValiadationError(
          `Stake below minmum stake amount: ${web3.utils.fromWei(
            stakingParameters._minimumStake
          )}`
        );
        return;
      }
      if (
        stakingParameters._maximumStake !== '0' &&
        Number(newStakeAmount) >
          Number(web3.utils.fromWei(stakingParameters._maximumStake))
      ) {
        setValiadationError(
          `Stake above maximum stake amount: ${web3.utils.fromWei(
            stakingParameters._maximumStake
          )}`
        );
        return;
      }
      const amountinWei = web3.utils.toWei(newStakeAmount, 'ether');
      await contract.methods.stake(amountinWei).send({
        from: account,
      });
      //Clear values
      setNewStakeAmount(0);
      refetchController.refetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onAuthorize = async eventSource => {
    try {
      setValiadationError('');
      setLoading(true);
      if (!newStakeAmount || Number(newStakeAmount) <= 0) {
        setValiadationError(
          'The amount to authorize has to be greater than 0.'
        );
        return;
      }
      const amountinWei = web3.utils.toWei(newStakeAmount, 'ether');
      await tokenContract.methods.approve(contractAddress, amountinWei).send({
        from: account,
      });
      setAccountAllowance(newStakeAmount);
      refetchController.refetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <h2>Dashboard</h2>
      </div>
      <div className={[cx('container'), cx('card'), cx('twoCols')].join(' ')}>
        <div className={cx('header')}>
            <h4>Stats</h4>
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Reward claimed:</h5>
          </label>
          <span>
            <h2>{formatNumber(web3.utils.fromWei(totalRewardsClaimed))}</h2>
          </span>
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Current APY:</h5>
          </label>
          <span>
            <h2>
              {formatPercentage(
                Number.parseInt(currentApy._apy) / Number.parseInt(currentApy._base)
              )}
            </h2>
          </span>
        </div>
        <div className={[cx('statsCard'),cx('footer')].join(' ')}>
          <label>
            <h5>Total amount staked (KATA):</h5>
          </label>
          <span>
            <h2>{formatNumber(web3.utils.fromWei(totalAmountStaked))}</h2>
          </span>
        </div>  
      </div>
        <div className={[cx('container'), cx('card')].join(' ')}>
          <div>
            <h4>Stake Your KATA coins</h4>
          </div>
          <div>
          <div className={[cx('numericStakeInput')].join(' ')}>
            <label htmlFor="Amount_kata">
              <input
                type="number"
                name="Amount (KATA)"
                id="Amount_kata"
                step={100}
                value={newStakeAmount}
                onChange={e => setNewStakeAmount(e.target.value)}
                min={0}
              />
              <span className={cx('placeholder')}>Amount (KATA)</span>
              <span className={cx('placeholderBottom')}>
                (Balance: {formatNumber(accountBalance)}) (Allowance:{' '}
                {formatNumber(accountAllowance)})
              </span>
              <button type="button" onClick={onMaxClicked}>
                MAX
              </button>
            </label>
          </div>
          </div>
          <>
            {!loading && (
              <div className={[cx('container'),cx('btnContainer')].join(' ')}>
                <div>
                  <button className='btn rounded' onClick={onAuthorize} disabled="true">
                    Authorize
                  </button>
                </div>
                <div>
                  <button className='btn rounded' onClick={onStake} disabled="true">
                    Stake
                  </button>
                </div>
              </div>
            )}
            {loading && (
              <LoaderSpinner type="ThreeDots" className={cx('spinner')} />
            )}
          </>
          {validationError && validationError !== '' && (
            <div className={cx('validationError')}>{validationError}</div>
          )}
        </div>
      <div>
        <StakesTable
          stakingParameters={stakingParameters}
          stake={normalizedStake}
          account={account}
          onWithdraw={onWithdraw}
        ></StakesTable>
      </div>
      <div>
        <div id="faq" className={[cx('container'), cx('card')].join(' ')}>
          <div className={cx('header')}>
              <h1>FAQ:</h1>
            </div>
        <Accordion className={[cx('container'), cx('card'), cx('accordionBody')].join(' ')}
                  defaultActiveKey="0">
          <Accordion.Item className={[cx('container'), cx('card')].join(' ')}  eventKey="0">
            <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}>
              <h4>What is STAKA?</h4>
            </Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p>STAKA is an ERC20 Token, you get in exchange of the KATA tokens you stake.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className={[cx('container'), cx('card')].join(' ')} eventKey="1">
            <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}><h4>Why do I get less STAKA tokens than the amount of KATA coins staked?</h4></Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p>
              The number of STAKA is calculated using the following formula:
              </p>
              <p>
              <Equation
                  value="Number of STAKA = KATA Invested * ((Total STAKA Minted) / (Total KATA)) * ((Contract End - Contract Start) / ((Contract End + Today) - (2* Contract start)))"
                  errorHandler={defaultErrorHandler}
                />
              </p>
              <p>
              This formula basically makes sure early stakers have more STAKA for the KATA they stakes.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className={[cx('container'), cx('card'), cx('accordionBody')].join(' ')} eventKey="2">
            <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}><h4>How are the rewards calculated?</h4></Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p>Rewards are calculated as follows:</p>
              <p>
              <Equation
                  value="r(tn) = TReward * (ST(s1) / TSTAKA(tn)) * ((tn - t(s1)) / (End - Start))"
                  errorHandler={defaultErrorHandler}
                />
              </p>
             
              <ul>
                <li>Where:</li>
                <li>rs1(tn): Reward for stake s1 at time tn</li>
                <li>TReward : Total reward pool</li>
                <li>ST(s1): Number of STAKA for stake s1</li>
                <li>TSTAKA(tn): Total STAKA coins at time tn</li>
                <li>t(s1): Time stake s1 has been created</li>
                <li>End and Start: respectively end and start of the satking period</li>
              </ul>
              <h5>If early unstaking penalty is in place the reward calculated above will be multiplied by the percentage of reward claimable this will depend on the early unstaking penalty parameters.</h5>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className={[cx('container'), cx('card'), cx('accordionBody')].join(' ')} eventKey="3">
            <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}><h4>How does reward penalty for early unstaking work?</h4></Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p color='blue'>
              <table>
                <tr>
                  <th colSpan={4}>Penalty parameters are as follows:</th>
                </tr>
                <tr>
                  <th>Immediate Reward percentage</th>
                  <th>Cliff duration (In days)</th>
                  <th>Cliff percentage</th>
                  <th>Linear reward increase period</th>
                </tr>
                  <tr>
                    <td>{formatPercentage(stakingParameters?._immediateRewardPercentage._percentage /stakingParameters?._immediateRewardPercentage._percentageBase)}
                    </td>
                    <td>{formatNumber(stakingParameters?._cliffDuration / (24 * 60 * 60))}
                    </td>
                    <td>{formatPercentage(stakingParameters?._cliffRewardPercentage._percentage /stakingParameters?._cliffRewardPercentage._percentageBase)}
                    </td>
                    <td>{formatNumber(stakingParameters?._linearDuration / (24 * 60 * 60))}
                    </td>
                  </tr>
              </table>
              </p>
              <p>
                The table below shows the percentage of reward you get depending on how long you had the stake for:
              <table>
                <tr>
                  <th>Unstake immediately</th>
                  <th>Unstake after {formatNumber(stakingParameters?._cliffDuration / (24 * 60 * 60))} days</th>
                  <th>Unstake between {formatNumber(stakingParameters?._cliffDuration / (24 * 60 * 60))} days and {formatNumber(stakingParameters?._linearDuration / (24 * 60 * 60))}</th>
                  <th>Unstake after {formatNumber(stakingParameters?._linearDuration / (24 * 60 * 60))} days</th>
                </tr>
                <tr>
                  <td>Get {formatPercentage(
                  stakingParameters?._immediateRewardPercentage._percentage /
                  stakingParameters?._immediateRewardPercentage._percentageBase
                )} of the reward</td>
                  <td>Get {formatPercentage(
                  stakingParameters?._immediateRewardPercentage._percentage /
                  stakingParameters?._immediateRewardPercentage._percentageBase
                )} of the reward</td>
                  <td> <Equation
                  value="Cliff percentage + (Number of days) / (Linear period length)"
                  errorHandler={defaultErrorHandler}
                /></td>
                  <td>Get 100% of the reward</td>
                </tr>
              </table>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className={[cx('container'), cx('card'), cx('accordionBody')].join(' ')} eventKey="4">
          <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}>
            <h4>Can I increase my existing stake, by staking more KATA coins?</h4></Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p>
              Yes you can. Let's assume you have an existing stake and you want to stake more coins.
              If you stake more KATA the new stake will be merged with the existing stake and the start date will be re-calculated.
              </p>
              <p>
                <Equation
                    value="New start date = ((Existing stake start date * Previous amount of STAKA) + (Date of the new Stake_today * New amount of STAKA)) / (Previous amount of STAKA + New amount of STAKA)"
                    errorHandler={defaultErrorHandler}
                  />
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className={[cx('container'), cx('card'), cx('accordionBody')].join(' ')} eventKey="5">
          <Accordion.Header className={[cx('header'), cx('accordionHeader')].join(' ')}><h4>Why is my reward balance going down?</h4></Accordion.Header>
            <Accordion.Body  className={[cx('statsCard')].join(' ')}>
              <p>
              The reward displayed above is the reward you would get if you unstake now, but this amount will change over time due to multiple parameters:
              Reward increases with time, as you are accumulating totalRewardsClaimed
              Reward decreases: as more people stake and your share of the total reward pool is diluted.
              When staking you get STAKA coins, these are used to calculate your share of the reward, at the beginning of staking you would get 1 STAKA for 1 KATA staked, but this ratiod will gradually drop so early staker will always have a more favorable exchange rate between KATA and STAKA.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  </div>
  );
};

export { StakingContractStakeholder };


