import LoaderSpinner from 'react-loader-spinner';
import { useWeb3, useWeb3Query, useWeb3MultiQuery } from 'hooks/useWeb3';
import { useContract } from 'hooks/useContract';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { AccountDropdown } from 'components/AccountDropdown';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { ConnectButton } from 'components/ConnectButton';
import { StakingContractAdmin } from 'components/StakingContractAdmin';
import { StakingContractStakeholder } from 'components/StakingView';
import { useRefetch } from 'hooks/useRefetch';
import VConsole from 'vconsole';
import MessageOverlay from 'components/MessageOverlay';

const cx = classNames.bind(styles);

const CONTRACT_ADDRESSES = {
  1: process.env.REACT_APP_STAKING_MAINNET_ADDRESS,
  56: process.env.REACT_APP_STAKING_BINANCE_MAINNET_ADDRESS,
  4: process.env.REACT_APP_STAKING_RINKEBY_ADDRESS,
  97: process.env.REACT_APP_STAKING_BINANCE_TEST_ADDRESS,
  1337: process.env.REACT_APP_STAKING_LOCAL_ADDRESS
};

const CHAINS_LABELS = {
  1: 'Ethereum',
  56: 'Binance Smart Chain',
  4: 'RINKEBY',
  97: 'BSC Testnet',
  1337: 'LOCAL TEST'
};

const NETWORKS_CONFIG = {
  56:{
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "BNB Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18
    },
    rpcUrls: [
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io"
    ],
    blockExplorerUrls: ["https://bscscan.com/"]
  }
};

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);
if(parsed && parsed?.debug === 'true')
{
  const vConsole = new VConsole();
  if(vConsole.isInited){}
}

const App = () => {
  const { account, connected, chainId } = useWeb3();
  const [switchingNetwork, setSwitchingNetwork] = useState(false);
  const contract = useContract('KatanaInuStakingContract', CONTRACT_ADDRESSES);

   

  const refetchController = useRefetch();
  useEffect(() => {
    return refetchController.setInterval(5_000);
  }, [refetchController]);

  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

 const {
    data: [
      owner,
      stakingParameters,
      rewardBalance,
      totalRewardsClaimed,
      totalAmountStaked,
      currentApy,
      requiredRewardAmount
    ],
    error,
    firstLoad: loading,
  } = useWeb3MultiQuery(
    useWeb3Query(contract,'owner'),
    useWeb3Query(contract,'getContractParameters'),
    useWeb3Query(contract,'getRewardBalance'),
    useWeb3Query(contract,'getTotalRewardsClaimed'),
    useWeb3Query(contract,'getTotalStaked'),
    useWeb3Query(contract,'getEstimationOfReward', {
                arguments: [Math.floor(oneYearFromNow.getTime() / 1000), 10000],
    }),0
    //useWeb3Query(contract,'getRequiredRewardAmount')
  );
  
  const stakingTokenContract = useContract('StakingTokenContract', stakingParameters?._stakingToken);
  useEffect(() => {
    if (!connected) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, loading]);

  const changeNetwork = async ({ id, setError }) => {
    try {

      setSwitchingNetwork(true);
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${Number(id).toString(16)}`}],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask. 4902 on deskto and -32603 on mobile
      if (switchError.code === 4902 || switchError.code === -32603) {
        if(!NETWORKS_CONFIG[id])
        {
          console.warn(`Network with Id (${id}) has not been defined in the list of networks.`);
          return;
        }
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...NETWORKS_CONFIG[id]
              }
            ]
          });
        } catch (addError) {
          console.error(`Error when trying to add network: ${JSON.stringify(addError)}`);
          return;
        }
      }
      console.error(`Error when trying to switch network: ${JSON.stringify(switchError)}`);
    }finally{
      setSwitchingNetwork(false);
    }
  };

  const [modalVisible, setModalVisible] = useState(true);
  return (
    <div className="App">
      <Header>
        <AccountDropdown />
      </Header>
      <div className={cx('content')}>
      <MessageOverlay modalVisible={modalVisible}
        setModalVisible={setModalVisible} /> 
        {((!!contract && loading) || switchingNetwork) && (
          <LoaderSpinner type="ThreeDots" className={cx('spinner')} />
        )}
        {!contract && (
          <>
            <p>
              Error: couldn't connect to Katana Inu staking smart contract. Are you on the
              right chain?
            </p>
            <ConnectButton />
          </>
        )}
        {!!error && (
          <>
            <p>Error: couldn't connect to Katana Inu staking smart contract. Are you on the
              right chain?</p>
          </>
        )}
        {!!contract && !loading && !error && !switchingNetwork && (
          <>
            <h1>KATANA INU STAKING</h1>
            <h3 className={cx('ChainLabel')}>{(CHAINS_LABELS[chainId])}</h3>
            {(window.ethereum && (
            <button className='btn rounded' onClick={() => changeNetwork(chainId === 1 ? {id:56} : {id:1})}>
            Switch to {(chainId === 1 ? 'BSC': 
                        'Ethereum')} Network
            </button>))}
            {(account === owner) && (
              <StakingContractAdmin contract={contract} stakingParameters={stakingParameters}
                          rewardBalance={rewardBalance} totalRewardsClaimed={totalRewardsClaimed}
                          currentApy={currentApy} totalAmountStaked={totalAmountStaked}
                          requiredRewardAmount={requiredRewardAmount} tokenContract={stakingTokenContract}>

              </StakingContractAdmin>
            )}
            {(account !== owner) && (
              <StakingContractStakeholder contract={contract} tokenContract={stakingTokenContract} 
                                          stakingParameters={stakingParameters} totalRewardsClaimed={totalRewardsClaimed}
                                          currentApy={{_apy:currentApy, _base:10000}} totalAmountStaked={totalAmountStaked}>
              </StakingContractStakeholder>
            )}
            
            {!connected && (
              <div>
                <ConnectButton />
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      </div>
  );
};

export { App };
