import React from "react";
import Alert from 'react-bootstrap/Alert';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const MessageOverlay = props => {
    const linkForm = 'https://docs.google.com/forms/d/e/1FAIpQLSeS6BuGOXf8rDeJe572QxZzzasYqcZ5JtxD05nsM3JdBIkBhw/viewform';
  return (<div variant="danger">
    <h1 className={cx('pAlert')}>Important Message from KATANA INU Team:</h1>
      <p>Staking System of Katana Inu is shutting down. The announcement was posted on December 23rd and postponed till 7th of January.

Your staked tokens are safe BUT you need to un-stake them ASAP in order not to loose them because we are eventually going to shut down the staking contract.

If you haven't un-staked your tokens in time there is no way to get the rewarded tokens back. 

If you are one of those users, fill out this form to get a % of the rewards from the staking pool: <Alert.Link href={linkForm}>Form</Alert.Link>
</p>
<p>Please fill this form completely (<Alert.Link href={linkForm}>Link to the form</Alert.Link>).</p>
<p>
We are working hard to bring you the best possible web3 game up to date and we don't plan to stop!

We are dedicated to bringing you the best web3 game and our journey is far from over!

We appreciate your understanding of this decision and thank you for being a part of the Katana Inu community.
</p>
      </div>
  );
};

export default MessageOverlay;
