import { useMemo, createContext, useContext } from 'react';
import { useWeb3 } from 'hooks/useWeb3';
import KatanaInuStakingContract from 'contracts/KatanaInuStakingContract.json';
import StakingTokenContract from 'contracts/StakingTokenContract.json';
import { createComposable } from 'utils/compose';

const ContractContext = createContext();

const ContractProvider = ({ children }) => {
  const { web3 } = useWeb3();

  const contracts = useMemo(
    () => ({ contracts: { KatanaInuStakingContract, StakingTokenContract }, cache: {} }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3]
  );

  return (
    <ContractContext.Provider value={contracts}>
      {children}
    </ContractContext.Provider>
  );
};

const withContractProvider = createComposable(ContractProvider);

const useContract = (name, address) => {
  const { web3, chainId } = useWeb3();
  const { contracts, cache } = useContext(ContractContext);

  if (!web3 || !chainId) {
    return null;
  }
  if (typeof address === 'object') {
    address = address[chainId];
  }
  
  if (!address) {
    return null;
  }

  address = address.toLowerCase();

  if (!contracts[name]) {
    return null;
  }

  if (!cache[name]) {
    cache[name] = {};
  }

  if (!cache[name][address]) {
    cache[name][address] = new web3.eth.Contract(contracts[name], address);
  }

  return cache[name][address];
};

export { ContractProvider, withContractProvider, useContract };
