import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
} from 'react';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { useRefetch } from 'hooks/useRefetch';
import { GENESIS } from 'utils/address';
import { createComposable } from 'utils/compose';

const PrivateTable = new WeakMap();
const Web3Context = createContext();

class BatchRequestQueue {
  constructor() {
    PrivateTable.set(this, { queue: [] });
  }

  get BatchRequest() {
    const { BatchRequest } = PrivateTable.get(this);
    return BatchRequest;
  }

  set BatchRequest(value) {
    PrivateTable.get(this).BatchRequest = value;
    this.process();
  }

  process() {
    const _ = PrivateTable.get(this);
    if (!_.BatchRequest) {
      return;
    }

    clearImmediate(_.immediate);
    _.immediate = setImmediate(() => {
      const requests = _.queue.splice(0, _.queue.length);

      if (!requests.length) {
        return;
      }

      const batch = new _.BatchRequest();
      for (const request of requests) {
        batch.add(request);
      }
      batch.execute();
      console.debug('executing', requests.length, 'requests');
    });
  }

  add(request) {
    const { queue } = PrivateTable.get(this);
    queue.push(request);
    this.process();
  }
}

const WEB3_URLS = {
  1: process.env.REACT_APP_WEB3_MAINNET_URL,
  4: process.env.REACT_APP_WEB3_RINKEBY_URL,
  56: process.env.REACT_APP_WEB3_BSC_URL,
  1337: process.env.REACT_APP_WEB3_LOCAL_URL
};

const WEB3_DEFAULT_CHAIN = Number(
  localStorage.getItem('defaultChain') ??
    process.env.REACT_APP_WEB3_DEFAULT_CHAIN
);

const createWeb3Modal = () =>
  new Web3Modal({
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: WEB3_URLS,
          network: 'mainnet',
        },
      },
    },
    disableInjectedProvider: false,
  });

const Web3Provider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [networkId, setNetworkId] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [connected, setConnected] = useState(false);
  const web3Modal = useMemo(createWeb3Modal, []);

  const batchRequestQueue = useMemo(() => new BatchRequestQueue(), []);

  const reset = useCallback(() => {
    setAccount(null);
    setChainId(WEB3_DEFAULT_CHAIN);
    setNetworkId(WEB3_DEFAULT_CHAIN);
    setWeb3(
      new Web3(new Web3.providers.HttpProvider(WEB3_URLS[WEB3_DEFAULT_CHAIN]))
    );
    setConnected(false);
  }, []);

  const subscribe = useCallback(
    (provider, web3) => {
      provider.on('close', reset);
      provider.on('accountsChanged', accounts => {
        setAccount(web3.utils.toChecksumAddress(accounts[0]));
        if (!accounts[0]) {
          reset();
        }
      });
      provider.on('chainChanged', async chainId => {
        const networkId = await web3.eth.net.getId();
        setChainId(Number(chainId));
        setNetworkId(Number(networkId));
      });
      provider.on('networkChanged', async networkId => {
        const chainId = await web3.eth.getChainId();
        setChainId(Number(chainId));
        setNetworkId(Number(networkId));
      });
    },
    [reset]
  );

  const connect = useCallback(async () => {
    const provider = await web3Modal.connect();
    if (!provider) {
      return;
    }

    const web3 = new Web3(provider);
    setWeb3(web3);

    subscribe(provider, web3);

    const accounts = await web3.eth.getAccounts();
    const account = web3.utils.toChecksumAddress(accounts[0]);
    const networkId = await web3.eth.net.getId();
    const chainId = await web3.eth.getChainId();

    setAccount(account);
    setNetworkId(Number(networkId));
    setChainId(Number(chainId));
    setConnected(true);
  }, [web3Modal, subscribe]);

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connect();
    }
  }, [web3Modal, connect]);

  const disconnect = useCallback(async () => {
    await web3?.currentProvider?.close?.();
    web3Modal?.clearCachedProvider?.();
    reset();
  }, [web3Modal, web3, reset]);

  useEffect(() => {
    if (web3) {
      batchRequestQueue.BatchRequest = web3.BatchRequest;
    }
  }, [web3, batchRequestQueue]);

  const context = useMemo(
    () => ({
      web3,
      connect,
      disconnect,
      account,
      networkId,
      chainId,
      connected,
      request: request => batchRequestQueue.add(request),
    }),
    [
      web3,
      connect,
      disconnect,
      account,
      networkId,
      chainId,
      connected,
      batchRequestQueue,
    ]
  );

  return (
    <Web3Context.Provider value={context}>{children}</Web3Context.Provider>
  );
};

const withWeb3Provider = createComposable(Web3Provider);

const useWeb3 = () => useContext(Web3Context);

const useWeb3Query = (
  contract,
  method,
  { arguments: args = [], skip = false } = {}
) => {
  const { web3, request } = useWeb3();
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState();
  const refetchController = useRefetch();

  const refetch = useCallback(
    async (canceller, i = 0) => {
      if (skip) {
        return;
      }

      if (!web3 || !contract) {
        return;
      }

      setLoading(true);

      try {
        const value = await new Promise((resolve, reject) => {
          const params = { from: GENESIS };
          let call;
          if (contract === web3) {
            call = web3.eth[method].request(...args, (err, value) => {
              if (err) {
                reject(err);
                return;
              }

              resolve(value);
            });
          } else {
            call = contract.methods[method](...args).call.request(
              params,
              (err, value) => {
                if (err) {
                  reject(err);
                  return;
                }

                resolve(value);
              }
            );
          }
          request(call);
        });

        if (canceller?.cancel) {
          return;
        }

        setError();
        setValue(value);
        setLoading(false);
        setFirstLoad(false);
      } catch (err) {
        console.error(`WEB3: Error when calling method ${method}: ${err}`);
        if (canceller?.cancel) {
          return;
        }

        if (i < 5) {
          await new Promise(resolve => setTimeout(resolve, 1000));
          return refetch(canceller, i + 1);
        }

        setError(err);
        setValue();
        setLoading(false);
        setFirstLoad(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(args), contract, method, request, web3, skip]
  );

  useEffect(() => {
    if (!contract) {
      return;
    }

    if (skip) {
      setLoading(false);
      setFirstLoad(false);
      return;
    }

    const canceller = { cancel: false };
    refetch(canceller);
    return () => (canceller.cancel = true);
  }, [refetch, contract, skip]);

  useEffect(() => {
    if (!refetchController || !contract || skip) {
      return;
    }

    const canceller = { cancel: false };
    const handler = () => refetch(canceller);
    refetchController.on('refetch', handler);
    return () => {
      refetchController.removeListener('refetch', handler);
      canceller.cancel = true;
    };
  }, [contract, refetchController, refetch, skip]);

  const result = useMemo(
    () => ({ data: value, loading, firstLoad, error, refetch }),
    [value, loading, firstLoad, error, refetch]
  );

  return result;
};

const useWeb3MultiQuery = (...queries) => {
  const data = queries.map(({ data }) => data);
  const error = queries.reduce((a, b) => a || b.error, null);
  const loading = queries.reduce((a, b) => a || b.loading, false);
  const firstLoad = queries.reduce((a, b) => a || b.firstLoad, false);
  return { data, error, loading, firstLoad };
};

export {
  Web3Provider,
  withWeb3Provider,
  useWeb3,
  useWeb3Query,
  useWeb3MultiQuery,
};
