import { SocialLinks } from 'components/Header/SocialLinks';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Footer = () => {
  return (
    <div className={cx('container')}>
      <div className="fw-title mb-25">
        <h5 className="title">Follow us</h5>
      </div>
      <div className="footer-social">
        <SocialLinks />
      </div>
      <div>
        Copyright &copy; 2022{'\u00a0'}
        <a href="https://chainvisiongames.com">ChainVision Games</a>. All Rights
        Reserved.
      </div>
    </div>
  );
};

export { Footer };
