import { Dropdown } from 'react-bootstrap';
import { ConnectButton } from 'components/ConnectButton';
import { Address } from 'components/Address';
import { useWeb3 } from 'hooks/useWeb3';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const AccountDropdown = () => {
  const { account, disconnect } = useWeb3();

  const onClickDisconnect = () => {
    disconnect();
  };

  if (!account) {
    return <ConnectButton />;
  }

  return (
    <Dropdown className="align-self-center">
      <Dropdown.Toggle className={cx('button')}>
        <Address address={account} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onClickDisconnect}>Disconnect</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export { AccountDropdown };
