import React, { useRef, createContext, useContext } from 'react';
import { EventEmitter } from 'events';
import { createComposable } from 'utils/compose';

class RefetchController extends EventEmitter {
  constructor() {
    super();
    this.setMaxListeners(1000);
  }

  refetch = () => {
    this.emit('refetch');
  };

  setInterval = ms => {
    const interval = setInterval(() => {
      this.emit('refetch');
    }, ms);

    return () => clearInterval(interval);
  };
}

const RefetchContext = createContext();

const RefetchProvider = ({ children }) => {
  const controller = useRef(new RefetchController());

  return (
    <RefetchContext.Provider value={controller.current}>
      {children}
    </RefetchContext.Provider>
  );
};

const withRefetchProvider = createComposable(RefetchProvider);

const useRefetch = () => useContext(RefetchContext);

export { RefetchProvider, withRefetchProvider, useRefetch };
