import React from 'react';
import $ from 'jquery';
import { SocialLinks } from './SocialLinks';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class Header extends React.Component {
  componentDidMount() {
    //Mobile Nav Hide Show
    if ($('.mobile-menu').length) {
      var mobileMenuContent = $('.menu-area .main-menu').html();
      $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);

      //Dropdown Button
      $('.mobile-menu li.menu-item-has-children .dropdown-btn').on(
        'click',
        function () {
          $(this).toggleClass('open');
          $(this).prev('ul').slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $('.mobile-nav-toggler').on('click', function () {
        $('body').addClass('mobile-menu-visible');
      });

      //Menu Toggle Btn
      $('.menu-backdrop, .mobile-menu .close-btn').on('click', function () {
        $('body').removeClass('mobile-menu-visible');
      });
    }
  }

  render() {
    const children = () => {
      let children = this.props.children;

      if (!Array.isArray(children)) {
        children = [children];
      }

      children = React.Children.map(children, child => {
        if (typeof child === 'object') {
          return React.cloneElement(child);
        }

        return child;
      });

      return children;
    };

    return (
      <header>
        <div className="header-top-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 m-auto">
                <div className="header-social">
                  <span>Follow us on :</span>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="menu-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler">
                  <i className="fas fa-bars" />
                </div>
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <a href="https://katanainu.com/">
                        <img
                          src="https://katanainu.com/assets/img/logo/logo.png"
                          alt="KATANA INU"
                        />
                      </a>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li>
                          <a href="https://katanainu.com/#">Gaming</a>
                        </li>
                        <li>
                          <a href="https://katanainu.com/#tokenomics">
                            Tokenomics
                          </a>
                        </li>
                        <li>
                          <a href="https://katanainu.com/#mission">
                            Why Katana Inu
                          </a>
                        </li>
                        <li>
                          <a href="https://katanainu.com/#roadmap">roadmap</a>
                        </li>
                        <li>
                          <a href="https://katanainu.com/#contact">contact</a>
                        </li>
                        <li>
                          <a href="#faq">FAQ</a>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action d-none d-sm-block">
                      {children()}
                    </div>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times" />
                    </div>
                    <div className="nav-logo">
                      <a href="https://katanainu.com/">
                        <img
                          src="https://katanainu.com/assets/img/logo/logo.png"
                          alt="KATANA INU"
                          title="KATANA INU"
                        />
                      </a>
                    </div>
                    <div className={cx('mobile-menu-actions')}>
                      {children()}
                    </div>
                    <div className="menu-outer">
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className="social-links">
                      <SocialLinks className="clearfix" />
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export { Header };
