import { Button } from 'react-bootstrap';
import { useWeb3 } from 'hooks/useWeb3';

import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ConnectButton = () => {
  const { connect } = useWeb3();

  const onClickConnect = () => connect();

  return (
    <Button className={cx('button')} onClick={onClickConnect}>
      CONNECT WALLET
    </Button>
  );
};

export { ConnectButton };
