import { useWeb3 } from 'hooks/useWeb3';
import { useRefetch } from 'hooks/useRefetch';
import { useState, useEffect } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import LoaderSpinner from 'react-loader-spinner';
import { Address } from 'components/Address';
import {
  formatSecsToDate,
  formatNumber,
  parseLocaleNumber,
  formatPercentage,
} from 'utils/Format';

const cx = classNames.bind(styles);

const StakingContractAdmin = ({
  contract,
  stakingParameters,
  rewardBalance,
  totalRewardsClaimed,
  totalAmountStaked,
  currentApy,
  requiredRewardAmount,
  tokenContract,
}) => {
  //className={cx('container')}
  const [contarctParameters, setContractParameters] =
    useState(stakingParameters);
  const [apy, setApy] = useState(currentApy);
  const [currentRewardBalance, setCurrentRewardBalance] =
    useState(rewardBalance);
  const [currentRequiredRewardAmount, setCurrentRequiredRewardAmount] =
    useState(requiredRewardAmount);
  const { web3, account } = useWeb3();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rewardDepositAmount, setRewardDepositAmount] = useState('');
  const refetchController = useRefetch();

  const onParmeterChanges = eventSource => {
    let newParameters = { ...contarctParameters };
    if (eventSource.target.getAttribute('data-is-eth') === 'true') {
      newParameters[eventSource.target.getAttribute('data-fieldname')] =
        web3.utils.toWei(
          parseLocaleNumber(eventSource.target.value).toString()
        );
    } else {
      if (
        eventSource.target.getAttribute('data-fieldname') === '_stakingDuration'
      ) {
        newParameters[eventSource.target.getAttribute('data-fieldname')] =
          Number.parseFloat(eventSource.target.value) * 365 * 24 * 60 * 60;
      } else if (
        eventSource.target.getAttribute('data-fieldname') ===
          '_cliffDuration' ||
        eventSource.target.getAttribute('data-fieldname') === '_linearDuration'
      ) {
        newParameters[eventSource.target.getAttribute('data-fieldname')] =
          Number.parseFloat(eventSource.target.value) * 24 * 60 * 60;
      } else if (
        eventSource.target.getAttribute('data-fieldname') ===
          '_immediateRewardPercentage' ||
        eventSource.target.getAttribute('data-fieldname') ===
          '_cliffRewardPercentage'
      ) {
        //_percentage
        newParameters[eventSource.target.getAttribute('data-fieldname')] = {
          _percentage: Number.parseFloat(eventSource.target.value) * 100,
          _percentageBase: 10000,
        };
      } else {
        newParameters[eventSource.target.getAttribute('data-fieldname')] =
          eventSource.target.value;
      }
    }
    setContractParameters(newParameters);
  };

  const onRewardDepositChanges = eventSource => {
    const newValue = parseLocaleNumber(eventSource.target.value).toString();
    setRewardDepositAmount(newValue);
  };

  useEffect(() => {
    if (!contract) {
      return;
    }
    if (isEditMode) {
      return;
    }
    const handler = () => {
      contract.methods
        .getContractParameters()
        .call({ from: account }, (err, result) => {
          if (err) {
            console.error(err);
          } else {
            setContractParameters(result);
          }
        });
      contract.methods.getAPY().call({ from: account }, (err, result) => {
        if (err) {
          console.error(err);
        } else {
          setApy(result);
        }
      });
      contract.methods
        .getRewardBalance()
        .call({ from: account }, (err, result) => {
          if (err) {
            console.error(err);
          } else {
            setCurrentRewardBalance(result);
          }
        });
      contract.methods
        .getRequiredRewardAmount()
        .call({ from: account }, (err, result) => {
          if (err) {
            console.error(err);
          } else {
            setCurrentRequiredRewardAmount(result);
          }
        });
    };
    handler();
    refetchController.on('refetch', handler);
    return () => refetchController.removeListener('refetch', handler);
  }, [refetchController, refetch, isEditMode, contract, account]);

  const onUpdateContractParameters = async eventSource => {
    try {
      setLoading(true);
      await contract.methods.updateStakingParameters(contarctParameters).send({
        from: account,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setRefetch(!refetch);
      setIsEditMode(false);
    }
  };

  const onDepositReward = async eventSource => {
    try {
      setLoading(true);
      const amount = web3.utils.toWei(rewardDepositAmount);
      await tokenContract.methods.transfer(contract._address, amount).send({
        from: account,
      });
      setRewardDepositAmount('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setRefetch(!refetch);
    }
  };

  const onWithdrawFromReward = async eventSource => {
    try {
      setLoading(true);
      const amount = web3.utils.toWei(rewardDepositAmount);
      await contract.methods.withdrawFromReward(amount).send({
        from: account,
      });
      setRewardDepositAmount('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setRefetch(!refetch);
    }
  };

  const onEditMode = () => {
    setIsEditMode(true);
  };

  const onCancelEditMode = () => {
    setIsEditMode(false);
    setRefetch(!refetch);
  };
  
  return (
    <div className={cx('container')}>
      <div className={cx('header')}>
        <h2>Staking Contract Dashboard</h2>
      </div>
      <div
        className={[cx('nestedContainer'), cx('mainContent'), cx('card')].join(
          ' '
        )}
      >
        <div className={[cx('parameterView')].join(' ')}>
          Minimum Stake:
          {!isEditMode && (
            <h4>
              {formatNumber(
                web3.utils.fromWei(contarctParameters?._minimumStake)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_minimumStake"
              data-is-eth={true}
              onChange={onParmeterChanges}
              value={formatNumber(
                web3.utils.fromWei(contarctParameters?._minimumStake)
              )}
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Maximum Stake:
          {!isEditMode && (
            <h4>
              {formatNumber(
                web3.utils.fromWei(contarctParameters?._maximumStake)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_maximumStake"
              data-is-eth={true}
              onChange={onParmeterChanges}
              value={formatNumber(
                web3.utils.fromWei(contarctParameters?._maximumStake)
              )}
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Maximum Supply:
          {!isEditMode && (
            <h4>
              {formatNumber(web3.utils.fromWei(contarctParameters?._maxSupply))}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_maxSupply"
              data-is-eth={true}
              onChange={onParmeterChanges}
              value={formatNumber(
                web3.utils.fromWei(contarctParameters?._maxSupply)
              )}
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Total Kata Reward:
          {!isEditMode && (
            <h4>
              {formatNumber(
                web3.utils.fromWei(contarctParameters?._totalReward)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_totalReward"
              data-is-eth={true}
              onChange={onParmeterChanges}
              value={formatNumber(
                web3.utils.fromWei(contarctParameters?._totalReward)
              )}
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Staking Token Address (ERC20 KATA):
          {!isEditMode && (
            <h4>
              <Address address={contarctParameters?._stakingToken} />
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_stakingToken"
              data-is-eth={false}
              onChange={onParmeterChanges}
              value={contarctParameters?._stakingToken}
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Reward distribution duration (Years):
          {!isEditMode && (
            <h4>
              {formatNumber(
                contarctParameters?._stakingDuration / (365 * 24 * 60 * 60)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_stakingDuration"
              data-is-eth={false}
              onChange={onParmeterChanges}
              defaultValue={
                contarctParameters?._stakingDuration /
                (365.0 * 24.0 * 60.0 * 60.0)
              }
              required
            />
          )}
        </div>
        {contarctParameters?._startDate === '0' && (
          <div className={[cx('parameterView')].join(' ')}>
            Minimum Staked Tokens before contract start:
            {!isEditMode && (
              <h4>
                {formatNumber(
                  web3.utils.fromWei(
                    contarctParameters?._minimumTotalStakeBeforeStart
                  )
                )}
              </h4>
            )}
            {isEditMode && (
              <input
                className={[cx('inputEdit')].join(' ')}
                data-fieldname="_minimumTotalStakeBeforeStart"
                data-is-eth={true}
                onChange={onParmeterChanges}
                value={formatNumber(
                  web3.utils.fromWei(
                    contarctParameters?._minimumTotalStakeBeforeStart
                  )
                )}
                required
              />
            )}
          </div>
        )}
        {contarctParameters?._startDate === '0' && (
          <div className={[cx('parameterView')].join(' ')}>
            Minimum Stakeholders before contract start:
            {!isEditMode && (
              <h4>
                {formatNumber(
                  contarctParameters?._minimumNumberStakeHoldersBeforeStart
                )}
              </h4>
            )}
            {isEditMode && (
              <input
                className={[cx('inputEdit')].join(' ')}
                data-fieldname="_minimumNumberStakeHoldersBeforeStart"
                data-is-eth={false}
                onChange={onParmeterChanges}
                value={formatNumber(
                  contarctParameters?._minimumNumberStakeHoldersBeforeStart
                )}
                required
              />
            )}
          </div>
        )}
        {contarctParameters?._startDate > 0 && (
          <div className={[cx('parameterView')].join(' ')}>
            Reward distribution start date:
            <h4>{formatSecsToDate(contarctParameters?._startDate)}</h4>
          </div>
        )}
        {contarctParameters?._endDate > 0 && (
          <div className={[cx('parameterView')].join(' ')}>
            Reward distribution end date:
            <h4>{formatSecsToDate(contarctParameters?._endDate)}</h4>
          </div>
        )}
        <div className={[cx('footer')].join(' ')}>
          <h3>Vesting Parameters</h3>
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Immediate Reward Percentage:
          {!isEditMode && (
            <h4>
              {formatPercentage(
                contarctParameters?._immediateRewardPercentage._percentage /
                  contarctParameters?._immediateRewardPercentage._percentageBase
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_immediateRewardPercentage"
              data-is-eth={false}
              onChange={onParmeterChanges}
              defaultValue={
                contarctParameters?._immediateRewardPercentage._percentage / 100
              }
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Cliff Reward Percentage:
          {!isEditMode && (
            <h4>
              {formatPercentage(
                contarctParameters?._cliffRewardPercentage._percentage /
                  contarctParameters?._cliffRewardPercentage._percentageBase
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_cliffRewardPercentage"
              data-is-eth={false}
              onChange={onParmeterChanges}
              defaultValue={
                contarctParameters?._cliffRewardPercentage._percentage / 100
              }
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Cliff Duration in days:
          {!isEditMode && (
            <h4>
              {formatNumber(
                contarctParameters?._cliffDuration / (24 * 60 * 60)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_cliffDuration"
              data-is-eth={false}
              onChange={onParmeterChanges}
              defaultValue={
                contarctParameters?._cliffDuration / (24.0 * 60.0 * 60.0)
              }
              required
            />
          )}
        </div>
        <div className={[cx('parameterView')].join(' ')}>
          Linear duration in days:
          {!isEditMode && (
            <h4>
              {formatNumber(
                contarctParameters?._linearDuration / (24 * 60 * 60)
              )}
            </h4>
          )}
          {isEditMode && (
            <input
              className={[cx('inputEdit')].join(' ')}
              data-fieldname="_linearDuration"
              data-is-eth={false}
              onChange={onParmeterChanges}
              defaultValue={
                contarctParameters?._linearDuration / (24.0 * 60.0 * 60.0)
              }
              required
            />
          )}
        </div>

        <div className={[cx('footer')].join(' ')}>
          {loading && (
            <LoaderSpinner type="ThreeDots" className={cx('spinner')} />
          )}
          {!loading && isEditMode && (
            <div className={[cx('mainContent'), cx('container')].join(' ')}>
            <div>
              <button className='btn rounded' onClick={onCancelEditMode}>
                Cancel
              </button>
            </div>
            <div>
              <button
                className='btn rounded'
                onClick={onUpdateContractParameters}
              >Update Parameters
              </button>
            </div>
            </div>
          )}
          {!loading && !isEditMode && (
            <button className='btn rounded' onClick={onEditMode}>
              Edit
            </button>
          )}
        </div>
      </div>
      <div className={[cx('mainContent'), cx('container')].join(' ')}>
        <div className={cx('header')}>
          <h2>Staking Statistics</h2>
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Total amount staked (KATA):</h5>
          </label>
          <span>
            <h2>{formatNumber(web3.utils.fromWei(totalAmountStaked))}</h2>
          </span>
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Current APY:</h5>
          </label>
          <span>
            <h2>
              {formatPercentage(
                Number.parseInt(apy._apy) / Number.parseInt(apy._base)
              )}
            </h2>
          </span>
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Reward balance / Required reward:</h5>
          </label>
          <span>
            <h2>
              {formatNumber(web3.utils.fromWei(currentRewardBalance))} /{' '}
              {!currentRequiredRewardAmount ? '' : formatNumber(web3.utils.fromWei(currentRequiredRewardAmount))}
            </h2>
          </span>
          
        </div>
        <div className={[cx('statsCard')].join(' ')}>
          <label>
            <h5>Reward claimed:</h5>
          </label>
          <span>
            <h2>{formatNumber(web3.utils.fromWei(totalRewardsClaimed))}</h2>
          </span>
        </div>
        <div className={[cx('footer'),cx('lightBg')].join(' ')}>
          <div className={[cx('container')].join(' ')}>
            <div  className={[cx('header'),cx('statsCard')].join(' ')}>
              <label>
                  <h5>Deposit / Withdraw Reward KATA:</h5>
              </label>
            </div>
              <div  className={[cx('header'),cx('statsCard')].join(' ')}>
                <input className={cx('depositInput')}
                  type="number"
                  value={rewardDepositAmount}
                  onChange={onRewardDepositChanges}
                  min="0"
                  step={100}
                  placeholder="Enter amount of KATA"></input>
              </div>
              
              {!loading && (
                <>
                <div className={[cx('statsCard')].join(' ')}>
                  <button className='btn rounded' onClick={onDepositReward}>
                    Deposit
                  </button>
                </div>
                <div className={[cx('statsCard')].join(' ')}>
                  <button
                    className='btn rounded'
                    onClick={onWithdrawFromReward}
                  >
                    Withdraw
                  </button>
                  </div>
                </>
              )}
              {loading && (
                <LoaderSpinner type="ThreeDots" className={cx('spinner')} />
              )}
                </div>
          </div>
      </div>
    </div>
  );
};

export { StakingContractAdmin };
